<!--
 * @Author: your name
 * @Date: 2021-08-05 17:35:16
 * @LastEditTime: 2021-08-17 16:45:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\record.vue
-->

<template>
  <div class="certificate">
    <img class="head" :src="avatar" />
    <p>{{ name }}</p>
    <p>{{ sex == 1 ? "男" : sex == 2 ? "女" : "" }}</p>
    <p>{{ number }}</p>
    <p>{{num}}小时</p>
  </div>
</template>
<script>
export default {
  props: ["avatar", "name", "sex", "number",'num'],
};
</script>
<style lang="scss" scoped>
.certificate {
 width: 617px;
height: 436px;
  background: url("../assets/img/fwzmBg.jpg") no-repeat center center / 100% 100%;
  position: relative;
  .head {
    width: 70px;
    height: 100px;
    position: absolute;
    top: 180px;
    left: 140px;
  }
  p {
    width: 200px;
    text-align: center;
    font-size: 11px;
    font-family: MicrosoftYaHei;
    color: #000000;
    position: absolute;
    &:nth-of-type(1) {
      top: 175px;
      right: 110px;
    }
    &:nth-of-type(2) {
      top: 200px;
      right: 110px;
    }
    &:nth-of-type(3) {
      top: 228px;
      right: 110px;
    }
    &:nth-of-type(4) {
      top: 256px;
      right: 90px;
    }
  }
}
</style>