<!--
 * @Author: your name
 * @Date: 2021-08-05 17:29:51
 * @LastEditTime: 2021-08-20 18:14:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\prove\materials.vue
-->
<template>
  <div class="materials">
    <record
      class="record"
      :name="volunteerName"
      :sex="sex"
      :number="volunteerCode"
      :avatar="headImg"
      :num="serverTime"
    />
    <div class="buttons flex-h flex-hc">
      <el-button @click="$router.push({ name: 'prove' })">返回</el-button>
      <el-button type="primary" @click="dialogFormVisible = true"
        >确定提交</el-button
      >
    </div>
    <el-dialog
      title="完善收货信息"
      :visible.sync="dialogFormVisible"
      center
      width="550px"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="width: 450px"
        size="small"
      >
        <el-form-item label="收货人姓名" prop="receiverName">
          <el-input
            v-model="ruleForm.receiverName"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="收货人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="receiverPhone">
          <el-input
            v-model="ruleForm.receiverPhone"
            maxlength="11"
            show-word-limit
            clearable
            placeholder="联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址" prop="receiverAddress">
          <el-input
            v-model="ruleForm.receiverAddress"
            maxlength="40"
            show-word-limit
            clearable
            placeholder="请输入邮寄地址"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import record from "@/components/record.vue";
    import { proveInfo, proveApply } from "@/api/index";
    const validatePhone = (rule, value, callback) => {
        if (!/^1\d{10}$/.test(value)) {
            callback(new Error("请输入正确格式手机号码"));
        } else {
            callback();
        }
    };
export default {
  components: {
    record,
  },
  data() {
    return {
      headImg: "",
      idCard: "",
      serverTime: "",
      activityNum: "",
      sex: "",
      volunteerCode: "",
      volunteerName: "",
      dialogFormVisible: false,
      ruleForm: {
        receiverName: "",
        receiverPhone: "",
        receiverAddress: "",
      },

      rules: {
        receiverName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        receiverPhone: [
            { required: true, validator: validatePhone, message: "请输入电话信息", trigger: "blur" },
        ],
        receiverAddress: [
          { required: true, message: "请输入邮寄地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getData() {
      proveInfo({id:""}).then((res) => {
        console.log(res);
        this.headImg = res.data.headImg;
        this.idCard = res.data.idCard;
        this.serverTime = res.data.serverTime;
        this.sex = res.data.sex;
        this.volunteerCode = res.data.volunteerCode;
        this.volunteerName = res.data.volunteerName;
        this.activityNum = res.data.activityNum;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          proveApply(this.ruleForm).then((res) => {
            this.dialogFormVisible=false;
            this.$alert(
              "我们将在7个工作日内为您盖章并寄出服务证书，请注意查收~",
              "提交成功",
              {
                confirmButtonText: "确定",
                callback: (action) => {
                 this.$router.push({ name: 'prove' })
                },
              }
            );
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.materials {
  .record {
    margin: 0 auto;
    margin-top: 80px;
  }
  .buttons {
    margin-top: 50px;
  }
}
</style>